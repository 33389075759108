@import '../../../../scss/theme-bootstrap';

.site-footer-email-signup {
  .site-email-signup__form {
    overflow: visible;
  }
  padding-bottom: 30px;
  input[type='email'],
  input[type='phone'] {
    display: block;
    width: 95%;
    border: 1px solid $color-white;
    color: $color-white;
    background: $color-black;
    height: 45px;
    padding: 0 18px;
    margin: 0 0 15px;
    line-height: 26px;
    @include breakpoint($medium-up) {
      max-width: 275px;
    }
    &::placeholder {
      color: $color-white;
      text-transform: uppercase;
    }
    &:-ms-input-placeholder {
      text-transform: uppercase;
    }
  }
  .site-footer &__gdpr-overlay {
    display: none;
    position: absolute;
    color: $color-black;
    background-color: $color-white;
    padding: 15px;
    bottom: 35px;
    width: 300px;
    border-radius: 5px;
    border: 1px solid $color-black;
    a {
      color: $color-black;
      display: inline;
      text-decoration: underline;
      &:hover {
        color: $color-black;
      }
    }
    p {
      margin: 0;
    }
  }
  &__gdpr-label {
    position: relative;
    padding-bottom: 20px;
    text-decoration: underline;
    cursor: pointer;
    &:hover > .site-footer-email-signup__gdpr-overlay {
      display: block;
      z-index: 99;
    }
  }
  &__terms-conditions {
    display: none;
    margin-bottom: 15px;
    a {
      color: $color-white;
      display: inline;
    }
  }
  &__email_promotions {
    margin-bottom: 15px;
  }
}
